import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class EnrolPracticalExamService{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/enrol/practical-exam';
    }
    createCartWithOutPayment(data) {
        let url = `${this.#api}/create`;
        return apiService.post(url, data);
    }
    createCartWithPayment(data) {
        let url = `${this.#api}/create/payment-completed`;
        return apiService.post(url, data);
    }
    createCartWithSchoolPayment(data) {
        let url = `${this.#api}/create/schoolpay-payment-completed`;
        return apiService.post(url, data);
    }

}